<template>
  <div class="button_edit">
    <div class="pl-4 mt-2">
      <div id="LabelSettingAccordion">
        <div class="headingText py-3" id="headingThree">
          <strong class="">
            <span>Label Setting</span>

            <a
              block
              v-b-toggle.labelSetting
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#buttonSetting"
              aria-expanded="false"
              aria-controls="buttonSetting"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse visible  id="labelSetting" accordion="timer-collapse" class=" pb-4">
          <div class="">
            <b-form-checkbox
              id="timerShowLabel"
              name="timerShowLabel"
              v-model="block.label.status"
              :value="true"
              :unchecked-value="false"
            >
              Show Label
            </b-form-checkbox>
          </div>
          <div v-if="block.label.status">
            <b-form-input
              v-model="block.label.text"
              placeholder="Label"
            ></b-form-input>
            <!-- Select Font -->
            <div class="row mt-2">
              <!-- <div class="col-md-6 col-12"><div data-v-37218993="" class="d-flex align-items-center"><span data-v-37218993="" class="mr-1"><svg data-v-37218993="" width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-37218993="" d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path data-v-37218993="" d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span><input data-v-37218993="" type="number" min="0" max="100" v-model="block.style.labelfontSize" class="form-control-sm w-50 px-2 ml-1 input-border-style mr-1" style="width: 50px;"><sub>px</sub></div></div> -->
              <div class="col-md-12 col-12 d-flex align-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <path
                    d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <select
                  class="form-select w-100 form-control-sm input-border-style m-0"
                  aria-label="Default select example"
                  v-model="block.style.label.fontFamily"
                >
              
<option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>
                </select>
              </div>
            </div>
            <!-- Text Styling -->
            <div class="col-12 p-0 d-flex align-items-center mt-2">
              <div class="px-0 w-25 d-flex align-items-center">
                <span class="">
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                      fill="#B3AFB6"
                    />
                    <path
                      d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  v-model="block.style.label.fontSize"
                  class="form-control-sm w-100 input-border-style pl-1 pr-0"
                />
              </div>

              <div class="d-flex align-items-center px-0">
                <span
                  type="button"
                  class="px-2 mx-1"
                  :class="{
                    'active-svg': block.style.label.fontWeight === 'bold',
                  }"
                  @click="
                    block.style.label.fontWeight =
                      block.style.label.fontWeight == 'normal'
                        ? 'bold'
                        : 'normal'
                  "
                >
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  type="button"
                  class="px-2"
                  :class="{
                    'active-svg': block.style.label.fontStyle === 'italic',
                  }"
                  @click="
                    block.style.label.fontStyle =
                      block.style.label.fontStyle == 'normal'
                        ? 'italic'
                        : 'normal'
                  "
                >
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  type="button"
                  class="px-2 mx-1"
                  :class="{
                    'active-svg':
                      block.style.label.textDecoration === 'underline',
                  }"
                  @click="
                    block.style.label.textDecoration =
                      block.style.label.textDecoration == 'underline'
                        ? 'unset'
                        : 'underline'
                  "
                >
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
              <div class="px-0 ml-2 d-flex">
                <span
                  class="mx-1 px-2"
                  type="button"
                  :class="{
                    'active-svg': block.style.label.textAlign === 'left',
                  }"
                  @click="block.style.label.textAlign = 'left'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  class="px-2"
                  type="button"
                  :class="{
                    'active-svg': block.style.label.textAlign === 'center',
                  }"
                  @click="block.style.label.textAlign = 'center'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  class="mx-1 px-2"
                  type="button"
                  :class="{
                    'active-svg': block.style.label.textAlign === 'right',
                  }"
                  @click="block.style.label.textAlign = 'right'"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <!-- Label Color -->
            <div
              class="row mb-2 align-items-center justify-content-between mt-2"
            >
              <div
                class="d-flex align-items-center col-12 col-md-8"
                title="Font Color"
              >
                <label
                  for=""
                  class="m-0"
                  style="white-space: nowrap;"
                  >Label:</label
                >
                <input
                  type="color"
                  v-model="block.style.label.color"
                  id="timer-label-color-picker"
                  style="min-width: 30px; width: 30px; height: 30px"
                  class="form-control-color form-control border-0"
                />
                <input
                  type="text"
                  class="form-control-sm input-border-style w-100"
                  v-model="block.style.label.color"
                />
              </div>
              <div
                class="d-flex align-items-center pl-2 col-12 col-md-3"
                title="Opacity"
              >
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.label.textOpacity"
                  max="100"
                  min="0"
                  class="form-control-sm input-border-style w-100"
                />
              </div>
            </div>
            <!-- Background Color -->
            <div
              class="row mb-2 align-items-center justify-content-between mt-2"
            >
              <div
                class="d-flex align-items-center col-12 col-md-9"
                title="Background Color"
              >
                <label
                  for=""
                  class="m-0"
                  style="white-space: nowrap;"
                  >Background:</label
                >
                <input
                  type="color"
                  v-model="block.style.label.backgroundColor"
                  id="timer-label-background-picker"
                  style="min-width: 30px; width: 30px; height: 30px"
                  class="form-control-color form-control border-0"
                />
                <input
                  type="text"
                  class="form-control-sm input-border-style w-100"
                  v-model="block.style.label.backgroundColor"
                />
              </div>
              <div
                class="d-flex align-items-center pl-2 col-12 col-md-3"
                title="Opacity"
              >
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.label.backgroundOpacity"
                  max="100"
                  min="0"
                  class="form-control-sm input-border-style w-100"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div id="TimerSettingAccordion">
        <div class="headingText py-3" id="headingThree">
          <strong class="">
            <span>Timer Setting</span>

            <a
              block
              v-b-toggle.timerSetting
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#timerSetting"
              aria-expanded="false"
              aria-controls="timerSetting"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse id="timerSetting" accordion="timer-collapse" class="pb-4">
          <div>
    <b-input-group class="mt-3">
    <template #append>
      <b-input-group-text><label for="timer-sec" class="m-0">Sec.</label></b-input-group-text>
    </template>
    <b-form-input id="timer-sec" type="number" v-model="block.timerValue" placeholder="Please Enter Time (Sec)"></b-form-input>
  </b-input-group>
  <b-input-group class="mt-3">
    <p class="font-weight-bold">Counter Setting</p>
    <b-row>
    <b-col cols="11" offset="1">
      <div class=" d-flex align-items-center mb-2">
         <span class="mr-2 custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-initial-text-${Date.now()}`"
          v-model="block.counterSetting.initial.visible"
        />
        <label class="custom-control-label" :for="`timer-initial-text-${Date.now()}`"></label>
      </span>
        <strong class="text-dark font-weight-medium">Initial Text </strong>
       
      </div> 
    <b-form-input id="initial Text" v-show="block.counterSetting.initial.visible" v-model="block.counterSetting.initial.text" type="text" placeholder="Initial Text"></b-form-input>
    </b-col>
    <b-col cols="11" offset="1" class="mt-2">
      <div class=" d-flex  align-items-center mb-2">
        <span class=" mr-2 custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-seconds-${Date.now()}`"
          v-model="block.counterSetting.seconds.visible"
        />
        <label class="custom-control-label" :for="`timer-seconds-${Date.now()}`"></label>
      </span>
        <strong class="text-dark font-weight-medium">Seconds </strong>
       
      </div> 
      <div  v-show="block.counterSetting.seconds.visible">
      <b-input-group>
        <template #append>
     
    <b-form-select class="m-0" v-model="block.counterSetting.seconds.textPosition" :options= "[
          { value: 'first', text: 'First' },
          { value: 'end', text: 'End' },
        ]"> </b-form-select>
    </template>
      <b-form-input id="initial Text"  v-model="block.counterSetting.seconds.text"  type="text" placeholder="Seconds"></b-form-input>
  </b-input-group>
</div>
    </b-col>
    <b-col cols="11" offset="1" class="mt-2">
      <div class=" d-flex j align-items-center mb-2">
        <span class=" mr-2 custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-minutes-${Date.now()}`"
          v-model="block.counterSetting.minutes.visible"
        />
        <label class="custom-control-label" :for="`timer-minutes-${Date.now()}`"></label>
      </span>
        <strong class="text-dark font-weight-medium">Minutes </strong>
        
      </div> 
      <div class="" v-show="block.counterSetting.minutes.visible">
      <b-input-group>
        <template #append>
     
    <b-form-select class="m-0" v-model="block.counterSetting.minutes.textPosition" :options= "[
          { value: 'first', text: 'First' },
          { value: 'end', text: 'end' },
        ]"> </b-form-select>
    </template>
      <b-form-input id="initial Text"  v-model="block.counterSetting.minutes.text"  type="text" placeholder="Minutes"></b-form-input>
  </b-input-group>
</div>
    </b-col>
    <b-col cols="11" offset="1" class="mt-2">
      <div class=" d-flex  align-items-center mb-2">
         <span class="mr-2 custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-hours-${Date.now()}`"
          v-model="block.counterSetting.hours.visible"
        />
        <label class="custom-control-label" :for="`timer-hours-${Date.now()}`"></label>
      </span>
        <strong class="text-dark font-weight-medium">Hours </strong>
       
      </div> 
      <div class="" v-show="block.counterSetting.hours.visible">
      <b-input-group>
        <template #append>
     
    <b-form-select class="m-0" v-model="block.counterSetting.hours.textPosition" :options= "[
          { value: 'first', text: 'First' },
          { value: 'end', text: 'End' },
        ]"> </b-form-select>
    </template>
      <b-form-input   v-model="block.counterSetting.hours.text"  type="text" placeholder="Hours"></b-form-input>
  </b-input-group>
</div>
    </b-col>
    <b-col cols="11" offset="1" class="mt-2 d-none">
      <div class=" d-flex  align-items-center mb-2">
        <strong class="text-dark font-weight-medium">Days </strong>
        <span class=" custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-day-${Date.now()}`"
          v-model="block.counterSetting.days.visible"
        />
        <label class="custom-control-label" :for="`timer-day-${Date.now()}`"></label>
      </span>
      </div> 
      <div class="" v-show="block.counterSetting.days.visible">
      <b-input-group>
        <template #append>
     
    <b-form-select class="m-0" v-model="block.counterSetting.days.textPosition" :options= "[
          { value: 'first', text: 'First' },
          { value: 'end', text: 'End' },
        ]"> </b-form-select>
    </template>
      <b-form-input id="initial Text"  v-model="block.counterSetting.days.text"  type="text" placeholder="Days"></b-form-input>
  </b-input-group>
</div>
    </b-col>
  </b-row>
   
  </b-input-group>
            <!-- Select Font -->
            <div class="row mt-2">
              <div class="col-md-12 col-12 d-flex align-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1"
                >
                  <path
                    d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <select
                  class="form-select w-100 form-control-sm input-border-style m-0"
                  aria-label="Default select example"
                  v-model="block.style.fontFamily"
                >
              
<option
v-for="font in getFontFamilyList"
:key="font.value"
:value="font.value"
>
{{ font.value }}
</option>
                </select>
              </div>
            </div>
            <!-- Text Styling -->
            <div class="col-12 p-0 d-flex align-items-center mt-2">
              <div class="px-0 w-25 d-flex align-items-center">
                <span class="">
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                      fill="#B3AFB6"
                    />
                    <path
                      d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <input
                  type="number"
                  min="0"
                  max="100"
                  v-model="block.style.fontSize"
                  class="form-control-sm w-100 input-border-style pl-1 pr-0"
                />
              </div>

              <div class="d-flex align-items-center px-0">
                <span
                  type="button"
                  class="px-2 mx-1"
                  :class="{ 'active-svg': block.style.fontWeight === 'bold' }"
                  @click="
                    block.style.fontWeight =
                      block.style.fontWeight == 'normal' ? 'bold' : 'normal'
                  "
                >
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  type="button"
                  class="px-2"
                  :class="{ 'active-svg': block.style.fontStyle === 'italic' }"
                  @click="
                    block.style.fontStyle =
                      block.style.fontStyle == 'normal' ? 'italic' : 'normal'
                  "
                >
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  type="button"
                  class="px-2 mx-1"
                  :class="{
                    'active-svg': block.style.textDecoration === 'underline',
                  }"
                  @click="
                    block.style.textDecoration =
                      block.style.textDecoration == 'underline'
                        ? 'unset'
                        : 'underline'
                  "
                >
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
              <div class="px-0 ml-2 d-flex">
                <span
                  class="mx-1 px-2"
                  type="button"
                  :class="{ 'active-svg': block.style.textAlign === 'left' }"
                  @click="block.style.textAlign = 'left'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  class="px-2"
                  type="button"
                  :class="{ 'active-svg': block.style.textAlign === 'center' }"
                  @click="block.style.textAlign = 'center'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  class="mx-1 px-2"
                  type="button"
                  :class="{ 'active-svg': block.style.textAlign === 'right' }"
                  @click="block.style.textAlign = 'right'"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <!-- Width and radius -->
            <div class="d-flex mt-2">
              <div class="form-group d-flex align-items-center">
                <label for="" class="mb-0 text-muted">W</label
                ><input type="number" v-model="block.style.buttonWidth" class="form-control-sm w-100 input-border-style" />
              </div>
              <div class="form-group d-flex align-items-center">
                <label for="" class="mb-0 text-muted">H</label
                ><input type="number"  v-model="block.style.buttonHeight" class="form-control-sm w-100 input-border-style" />
              </div>
              <div class="form-group d-flex align-items-center">
                <label for="" class="mb-0 text-muted"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
                      fill="#B3AFB6"
                    ></path></svg></label
                ><input type="number" v-model="block.style.borderRadius" class="form-control-sm w-100 input-border-style" />
              </div>
              <div class="form-group d-flex align-items-center">
                <label for="" class="mb-0 text-muted"
                  ><svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                      fill="#B3AFB6"
                    ></path></svg></label
                ><input type="number" v-model="block.style.borderSize" class="form-control-sm w-100 input-border-style" />
              </div>
              <div class="form-group d-flexx align-items-center d-none">
                <label for="" class="mb-0 text-muted"
                  ><svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.51852 3.05788L0.166656 10.4097V13.1667H2.9236L10.2755 5.81483L7.51852 3.05788ZM2.3134 11.6963H1.63703V11.0199L7.51852 5.13846L8.19489 5.81483L2.3134 11.6963ZM12.4516 3.63868C12.7383 3.35195 12.7383 2.88879 12.4516 2.60206L10.7313 0.881729C10.4446 0.595006 9.98139 0.595006 9.69467 0.881729L8.2537 2.32269L11.0107 5.07964L12.4516 3.63868Z"
                      fill="#B3AFB6"
                    ></path></svg></label
                ><input
                  type="color"
                  class="form-control-color"
                  style="min-width: 50px; width: 50px"
                />
              </div>
            </div>
            <!-- Label Color -->
            <div
              class="row mb-2 align-items-center justify-content-between mt-2"
            >
              <div
                class="d-flex align-items-center col-12 col-md-8"
                title="Font Color"
              >
                <label
                  for=""
                  class="m-0"
                  style="white-space: nowrap;"
                  >Text:</label
                >
                <input
                  type="color"
                  v-model="block.style.color"
                  id="timer-label-color-picker"
                  style="min-width: 30px; width: 30px; height: 30px"
                  class="form-control-color form-control border-0"
                />
                <input
                  type="text"
                  class="form-control-sm input-border-style w-100"
                  v-model="block.style.color"
                />
              </div>
              <div
                class="d-flex align-items-center pl-2 col-12 col-md-3"
                title="Opacity"
              >
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.textOpacity"
                  max="100"
                  min="0"
                  class="form-control-sm input-border-style w-100"
                />
              </div>
            </div>
            <!-- Background Color -->
            <div
              class="row mb-2 align-items-center justify-content-between mt-2"
            >
              <div
                class="d-flex align-items-center col-12 col-md-9"
                title="Background Color"
              >
                <label
                  for=""
                  class="m-0"
                  style="white-space: nowrap;"
                  >Background:</label
                >
                <input
                  type="color"
                  v-model="block.style.backgroundColor"
                  id="timer-label-background-picker"
                  style="min-width: 30px; width: 30px; height: 30px"
                  class="form-control-color form-control input-border-style"
                />
                <input
                  type="text"
                  class="form-control-sm input-border-style w-100"
                  v-model="block.style.backgroundColor"
                />
              </div>
              <div
                class="d-flex align-items-center pl-2 col-12 col-md-3"
                title="Opacity"
              >
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.backgroundOpacity"
                  max="100"
                  min="0"
                  class="form-control-sm input-border-style w-100"
                />
              </div>
            </div>
          

         
            <div class="row m-0" v-if="isDesktopView">
          <h4 class="subheading my-4">Timer margin:</h4>
          <div class="row align-items-center mb-2">
            <div class="form-group col-6 d-flex mb-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginTop"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-6 d-flex mb-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginBottom"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-3 align-items-center">
            <div class="form-group col-6 d-flex mb-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginLeft"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-6 d-flex mb-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginRight"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" v-else>
          <h4 class="subheading mt-4">Mobile Timer margin:</h4>
          <div class="row m-0">
            <div class="row align-items-center">
              <div class="col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      min="0"
                      placeholder="Top"
                      v-model="block.style.mobileMarginTop"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      min="0"
                      placeholder="Bottom"
                      v-model="block.style.mobileMarginBottom"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="form-group col-4 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      v-model="block.style.mobileMarginLeft"
                      min="0"
                      placeholder="Left"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group col-4 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      v-model="block.style.mobileMarginRight"
                      min="0"
                      placeholder="Right"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          </div>
        </b-collapse>
      </div>
      <div id="TimerEndSettingAccordion">
        <div class="headingText py-3" id="headingThree">
          <strong class="">
            <span>Timer End Action</span>

            <a
              block
              v-b-toggle.eventSetting
              style="float: right; padding-right: 20px; cursor: pointer"
              class="arrow-icon-down"
              data-toggle="collapse"
              data-target="#buttonSetting"
              aria-expanded="false"
              aria-controls="buttonSetting"
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </a>
          </strong>
        </div>
        <hr />
        <b-collapse id="eventSetting" accordion="timer-collapse">
          <div class=" d-flex align-items-center mb-3">
        <span style="margin-right:12px;">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 3.33331L8.82504 4.50831L13.475 9.16665H3.33337V10.8333H13.475L8.82504 15.4916L10 16.6666L16.6667 9.99998L10 3.33331Z" fill="#4D4950"/>
          </svg>

        </span>
        <select
            class="form-select w-75 form-control-sm  reset-option"
            aria-label="Default select example"
            v-model="block.timerAction"
          >
          <option value="none" selected="selected">None</option>
          <option value="text">Display Text</option>
            <option value="nextPage">Next Page</option>
            <optgroup label="Go to URL:">
              <option value="newTab">New Tab</option>
            <option value="currentTab">Current Tab</option>
            </optgroup>
            
          </select>
          <!-- <span style="margin-left:12px;" >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2736 9.125C13.0438 8.11875 11.4484 7.5 9.68683 7.5C6.92147 7.5 4.54166 9.0125 3.41159 11.2063C3.19887 11.625 3.43818 12.125 3.91015 12.275C4.30235 12.4 4.72779 12.225 4.90727 11.875C5.77145 10.2125 7.58622 9.0625 9.68683 9.0625C10.9831 9.0625 12.1663 9.5125 13.0904 10.2375L11.8207 11.4313C11.4019 11.825 11.6944 12.5 12.286 12.5H16.002C16.3676 12.5 16.6667 12.2188 16.6667 11.875V8.38125C16.6667 7.825 15.9488 7.54375 15.53 7.9375L14.2736 9.125Z" fill="#4D4950"/>
            </svg>
          </span> -->
        </div>
        <div v-if="block.timerAction == 'newTab' || block.timerAction == 'currentTab'">
            <div class="row">
                      <div class="col-12 px-0 mt-2">
                        <div class="d-flex align-items-center mx-2">
                          <span class="mr-2 font-weight-medium text-dark" >URL: </span>
                          <input
                            type="text"
                            class="px-3 py-1 w-100 form-control-input"
                            style="
                              border: none;
                              border-radius: 2px;
                              outline: none;
                            "
                            v-model="block.timerActionLink"
                            placeholder="URL Link"
                          />
                        </div>
                      </div>
            </div>
          </div>
          <div v-if="block.timerAction == 'text'">
            <div class=" d-flex justify-content-between align-items-center mb-2">
        <strong class="text-dark font-weight-medium">Show Preview </strong>
        <span class=" custom-control custom-switch" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`timer-preview-${Date.now()}`"
          v-model="block.actionText.preview"
        />
        <label class="custom-control-label" :for="`timer-preview-${Date.now()}`"></label>
      </span>
      </div>
    <b-form-input
      v-model="block.actionText.text"
      placeholder="Action Text"
    ></b-form-input>
  </div>
        </b-collapse>
        </div>
      
     
      
    </div>
  </div>
</template>
<script>
import fontFamilyList from "../../../staticPages/fontFamilyList";
export default {
  
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
  },
  data() {
    return {
      showBgOpacity: true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

    //   this.contentLocal = this.content;
  },
  methods: {
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorOutput(value) {
      let isHex = this.checkHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
  }
};
</script>
<style>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 2px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}
.reset-option {
  border: 0.3px solid #6d6b6d;
  box-sizing: border-box;
  border-radius: 4px;
}
.ql-container.ql-snow {
  background: white;
}
.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}
.subheading {
  color: #18191c;
  font-size: 14px;
  font-weight: 700;
}
.subheading::first-letter {
  text-transform: uppercase;
}
.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}
.active-svg path {
  fill: #323232;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }
</style>
